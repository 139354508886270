import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true
  },
  {
    path: '/aanmelden',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Aanmelden.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// scroll to top on route change
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
