import { createStore } from 'vuex'
import aanmelding from './aanmelding'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  modules: {
    aanmelding,
  },
  plugins: [createPersistedState()]
})
