import Axios from 'axios'

const getDefaultState = () => {
    return {
        naam: '',
        email: '',
        afleveradres: '',
        afleverdatum: '',
        aantal: '',
        allowReset: false
    }
  }
  
const aanmelding = {
    namespaced: true,
    state: getDefaultState(),
    actions : {
        async submit({commit, state}) {
            try {
                const response = await Axios.post('submit_aanmelding', state)
                state.allowReset = true
                return response
            } catch (error) {
                return error.response
            }
        },
        resetState({commit}) {
            commit('resetState')
        },
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setFormValue(state, {key, value}) {
            state[key] = value
        },

    }
}

export default aanmelding