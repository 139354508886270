<template>
  <div class='content-background'>
    <div class="bg-image-full" id='home-bg-image'>
      <div id="hero">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <h1 class="display-2 text-center">{{succesText}}</h1>
              <router-link to="/aanmelden" class="btn btn-theme-primary">Aanmelden voor een knijper</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row">
        <div class="col-md-12 d-grid" id="step-cards">
          <div class='theme-card'>
            <div class="theme-card-image bg-image-full"
              :style= "{'background-image': 'url(' + require('@/assets/img/ophalen.jpg') + ')'}">
            </div>
            <div class="theme-card-content">
              <h3>1. Knijper regelen</h3>
              <p>Meld je aan om gratis een knijper te lenen, deze bezorgen we thuis! Je kunt de knijpers ook op 23 maart ophalen bij Schilte. </p>
            </div>
          </div>
          <div class='theme-card'>
            <div class="theme-card-image bg-image-full"
              :style= "{'background-image': 'url(' + require('@/assets/img/schoonmaken.jpg') + ')'}">
            </div>
            <div class="theme-card-content">
              <h3>2. Ruim op</h3>
              <p>Doe ook mee met je gezin, buren, vrienden of collega’s. Maak jouw straat, wijk of de buurt van jouw school, bedrijf of vereniging schoon!</p>
            </div>
          </div>
          <div class='theme-card'>
            <div class="theme-card-image bg-image-full"
              :style= "{'background-image': 'url(' + require('@/assets/img/feestje.jpg') + ')'}">
            </div>
            <div class="theme-card-content">
              <h3>3. Friet en feest</h3>
              <p>Lever je afval en knijpers tussen 13:30 en 15:00 uur in bij Schilte. Als dank krijg je 2 frietjes per knijper! </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  computed: {
    succesText(){
      if (this.$store.state.aanmelding.allowReset === false){
        return "Zet je op zaterdag 23 maart in voor IJsselstein tijdens de nationale opschoondag"; 
      }
      let string =  "Goed bezig, " + this.$store.state.aanmelding.naam.charAt(0).toUpperCase() + this.$store.state.aanmelding.naam.slice(1) + "!";
      if (this.$store.state.aanmelding.afleverdatum === 0){
        string += " Je kunt de knijpers op 23 maart ophalen bij Schilte.";
      } else {
        string += " We komen de knijpers op " + this.$store.state.aanmelding.afleverdatum + " maart bij je langsbrengen.";
      }
      return string;
    }
  },
}
</script>

<style scoped>
.content-background{
  background: var(--light);
}
#home-bg-image{
  background-image: url('../assets/img/earth-view-rot.jpg');
  color: #06192c;
  text-align: center;
  min-height: 100vh;
}
#hero{
  --hero-background-opacity: .82;
  --hero-background-rotation: 0deg;
  background: rgb(86,233,252);
  background: -moz-linear-gradient(var(--hero-background-rotation), rgba(86,233,252,var(--hero-background-opacity)) 0%, rgba(252,195,35,var(--hero-background-opacity)) 100%);
  background: -webkit-linear-gradient(var(--hero-background-rotation), rgba(86,233,252,var(--hero-background-opacity)) 0%, rgba(252,195,35,var(--hero-background-opacity)) 100%);
  background: linear-gradient(var(--hero-background-rotation), rgba(86,233,252,var(--hero-background-opacity)) 0%, rgba(252,195,35,var(--hero-background-opacity)) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#56e9fc",endColorstr="#fcc323",GradientType=1);
  padding: 150px 0;
  color: #1b1555;
  text-align: center;
  min-height: 100vh;
}

#step-cards{
  margin-top: -200px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 768px){
  #step-cards{
    margin-top: -100px;
    grid-template-columns: 1fr;
  }
}

</style>