<template>
    <div>
        <div id="navbar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-center">
                        <div id="outer-skew">
                            <div id="inner-skew">
                                <p>IJsselstein<br><span class="logo-alt">schoon</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    #navbar{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    #outer-skew{
        transform: skewY(-5deg);
        text-align: center;
        width: 200px;
        /* background: var(--primary); */
        border-radius: 15px;
        padding: 30px 0 1px;
        margin-top: -20px;
    }
    #inner-skew{
        line-height: 1.2;
        transform: skewY(5deg);
        color: white;
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: 600;
    }
</style>