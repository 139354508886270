<template>
    <div>
        <div id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p>IJsselstein Schoon is een samenwerking van de gemeente IJsselstein, Stichting Enjoycleaningup, Noplasticrunner en Knijperploeg IJsselveld Oost. Met dank aan Terberg, Schilte en Frietenliefde. Luchtfoto door @dronepilotijsselstein.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#footer{ 
    background: var(--light);
    min-height: 50px;
    padding: 20px 0;
    border-top: 1px solid rgba(0,0,0,.04);
}
</style>