import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Axios from 'axios'

Axios.defaults.baseURL = 'https://ijsselstein-schoon-serv.cld9.nl/api/';
Axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "@/assets/css/theme.css"

createApp(App).use(store).use(router).mount('#app')
